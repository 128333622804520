html body{
    background-color:rgb(149, 241, 211);
}

tr:nth-child(odd){
background-color: navajowhite
}
tr:nth-child(even){
    background-color: coral;
}

div > h2 #section{
    font-family:cursive;
}
h2:hover{
    border-left-style: solid;
    border-left-color: slategrey;
}
a:visited {
    color:purple;
}

/*https://www.w3schools.com/howto/howto_css_display_element_hover.asp*/
.hide{
    display: none;
}

h2 + table ul > li:hover > li.hide{
    display: block;
    color: royalblue;
}

[right-align]{
    text-align: right;
}